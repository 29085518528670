export const userRoles = [
  {
    label: '1st Line',
    value: 'firstline',
  },
  {
    label: '2nd Line',
    value: 'secondline',
  },
  {
    label: 'Team Lead/Trainer',
    value: 'teamlead',
  },
  {
    label: 'Admin',
    value: 'administrator',
  },
  // {
  //   label: 'Colab:1st Line',
  //   value: 'secondline',
  // },
  // {
  //   label: 'Colab:2nd Line',
  //   value: 'teamlead',
  // },
  {
    label: 'Colab:Admin',
    value: 'colabadmin',
  },
];

export function mapValuesToLabels(value: string) {
  const role = userRoles.find(role => role.value.toLowerCase() === value.toLowerCase());
  return role ? role.label : value;
}
