import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { Alert, Button, Dialog, Dropdown, DropdownItem, GlobalContext, Icon, RatingCard } from 'lynkco-up-core';
import { CardActions } from 'lynkco-up-core/dist/types/components/SimpleCard';
import { GenericObject, ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { DetailsMap, StatusDisk, ColDefinitionDetails, RowDefinitionDetails } from '../../components';
import { SelectedBookingContext } from '../../modules/Bookings/selectedBookingContext';
import { useSelectedBookingActions } from '../../modules/Bookings/selectedBookingReducer';
import { Booking } from '../../services/bookings/types';
import {
  DetailsSection,
  DetailsSectionHeading,
  formatDate,
  getBookingLocation,
  redirectToDynamicsURL,
} from '../shared/utils';
import {
  bookingStatusNames,
  getTimeDifference,
  isOvertimeBooking,
  isLateReturnBooking,
  TerminateAction,
  getReturnTimeDelta,
  isExtendedBooking,
  buildStripeUrl,
  isInTheProblemTimespan,
} from './utils';
import { extendBooking } from '../../services/bookings';
import { copyToClipboard } from '../../utils';
import { copyFormattedDetailsToClipboard } from '../shared/utils';
import PriceAndCurrency from '../shared/PriceAndCurrency';
import { useUrlQuery } from '../../hooks';
import OvertimeAlert from './OvertimeAlert';
import { useUserContext } from '../../modules/Users/UserContext';

type OverviewProps = {
  booking: Booking;
  onUserSearch: (customerID: string) => void;
};

function Overview({ booking, onUserSearch }: OverviewProps) {
  const { alert, loading } = useContext(GlobalContext);
  const { dispatch: selectedBookingDispatch } = useContext(SelectedBookingContext);
  const { setIsBookingTermination } = useSelectedBookingActions(selectedBookingDispatch);
  const [isExtendDialogOpen, setIsExtendDialogOpen] = useState(false);
  const [isRedirectToStripeDialogOpen, setIsRedirectToStripeDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [extendedDate, setExtendedDate] = useState<string>('');
  const bookingCoordinates = {
    lat: booking.location.latitude,
    lng: booking.location.longitude,
  };
  const searchQuery = useUrlQuery();
  const [, setSearchParams] = useSearchParams();
  const hasBookingIdURLParam = searchQuery.find(item => item[0] === 'bookingId');
  // const isOngoing = isOngoingBooking(booking);
  const isLate = isLateReturnBooking(booking);
  const isOvertime = isOvertimeBooking(booking);
  const isExtended = isExtendedBooking(booking);
  const showOvertimeAlert = isOvertime || isLate;
  const showFuelErrorAlert = isInTheProblemTimespan(booking);

  function handleCopyToClipboard(value: string) {
    copyToClipboard(value, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function handleCopyAllDetailsToClipboard() {
    copyFormattedDetailsToClipboard(booking, () => {
      alert.show('Error copying to clipboard.');
    });
  }

  function handleBookingTermination() {
    setIsBookingTermination();
  }

  function handleRedirectToDynamicsUrl(id: string) {
    try {
      redirectToDynamicsURL(id);
    } catch (error) {
      if (error instanceof Error) {
        alert.show(error.message);
      }
    }
  }

  function handleCloseExtendDialog() {
    setExtendedDate('');
    setIsExtendDialogOpen(false);
  }

  // function handleOpenExtendDialog() {
  //   setErrorMessage('');
  //   setIsExtendDialogOpen(true);
  // }

  const handleExtensionUpdate: ReactInputEventHandler = e => {
    setExtendedDate(e.currentTarget.value);
  };

  function customerOptions(customerID: string) {
    return [
      {
        icon: 'content_copy',
        label: 'Customer ID',
        onClick: () => handleCopyToClipboard(customerID),
      },
      {
        icon: 'search',
        label: 'Search all bookings',
        onClick: () => onUserSearch(customerID),
      },
      {
        icon: 'badge',
        label: 'Open contact',
        onClick: () => handleRedirectToDynamicsUrl(customerID),
      },
    ] as CardActions[];
  }

  function getMininumExtendedDate() {
    const minDate = booking.extension ? booking.extension.endTime : booking.end;
    return dayjs(minDate).format('YYYY-MM-DDThh:mm');
  }

  function handleOpenRedirectToStripeDialog() {
    setIsRedirectToStripeDialogOpen(true);
  }

  function handleCloseRedirectToStripeDialog() {
    setIsRedirectToStripeDialogOpen(false);
  }

  function handleRedirectToStripe() {
    const url = buildStripeUrl(booking.vehicle.market, booking.orderReference);

    handleCloseRedirectToStripeDialog();
    window.open(url, '_blank');
  }

  async function handleExtendBooking() {
    if (!extendedDate) {
      setErrorMessage(`Please select an extend date`);
      return;
    }

    if (getTimeDifference(extendedDate, booking.end) < 0) {
      setErrorMessage('The selected date is prior to the booking end date');
      return;
    }

    try {
      loading.show();
      const newExtendedDate = dayjs(extendedDate).format();
      await extendBooking(booking.id, newExtendedDate);
      alert.show(`The booking was successfully extended.`, 'success');
    } catch (err) {
      alert.show(`Could not extend the booking.`, 'error');
    }

    handleCloseExtendDialog();
    loading.hide();
  }

  useEffect(() => {
    if (!hasBookingIdURLParam) {
      setSearchParams([...searchQuery, ['bookingId', booking.id]] as GenericObject);
    }
  }, []);

  function hasAccess(scope: string) {
    const { checkAccess } = useUserContext();
    return checkAccess(scope);
  }

  return (
    <div>
      <DetailsSection>
        {showFuelErrorAlert && (
          <Alert
            type={'error'}
            heading={'Problem in Fuel Calculation'}
            body={
              'This booking is in a timespan where fuel calculation may be problematic, please create an Ivanti ticket for Connectivity Backend team if you see a problem'
            }
          />
        )}
        {showOvertimeAlert && <OvertimeAlert booking={booking} />}
        <div className="flex justify-between items-center">
          <DetailsSectionHeading>Booking details</DetailsSectionHeading>
          <div className="mb-7">
            <Dropdown icon="expand_more" label="More" extraClasses="mr-2">
              <DropdownItem onClick={handleCopyAllDetailsToClipboard}>
                <div className="flex justify-between items-center">
                  <span>All details</span>
                  <Icon name="content_copy" />
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => handleCopyToClipboard(booking.id)}>
                <div className="flex justify-between items-center">
                  <span>Booking ID</span>
                  <Icon name="content_copy" />
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => handleCopyToClipboard(booking.orderReference)}>
                <div className="flex justify-between items-center">
                  <span>Order Reference</span>
                  <Icon name="content_copy" />
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => handleCopyToClipboard(booking.lender.snowflakeId)}>
                <div className="flex justify-between items-center">
                  <span>Lender ID</span>
                  <Icon name="content_copy" />
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => handleCopyToClipboard(booking.borrower.snowflakeId)}>
                <div className="flex justify-between items-center">
                  <span>Borrower ID</span>
                  <Icon name="content_copy" />
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => handleCopyToClipboard(booking.vehicle.vin)}>
                <div className="flex justify-between items-center">
                  <span>Vehicle ID (VIN)</span>
                  <Icon name="content_copy" />
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => handleCopyToClipboard(booking.vehicle.regNr)}>
                <div className="flex justify-between items-center">
                  <span>Vehicle License Plate</span>
                  <Icon name="content_copy" />
                </div>
              </DropdownItem>
            </Dropdown>
            {/* {isOngoing && hasAccess('FUNCTION_EXTEND_BOOKING') && (
              <Button variant="white" extraClasses="mr-2" onClick={handleOpenExtendDialog}>
                Extend booking
              </Button>
            )} */}
            {hasAccess('FUNCTION_FORCE_END') && (
              <TerminateAction type="button" booking={booking} onClick={handleBookingTermination} />
            )}
          </div>
        </div>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <ColDefinitionDetails label="Lender">
            <RatingCard
              src={booking.lender.profilePicture}
              alt={booking.lender.firstName}
              label={booking.lender.firstName}
              ratingValue={booking.lender.rating}
              options={customerOptions(booking.lender.snowflakeId)}
            />
          </ColDefinitionDetails>
          <ColDefinitionDetails label="Borrower">
            <RatingCard
              src={booking.borrower.profilePicture}
              alt={booking.borrower.firstName}
              label={booking.borrower.firstName}
              ratingValue={booking.borrower.rating}
              options={customerOptions(booking.borrower.snowflakeId)}
            />
          </ColDefinitionDetails>
          <ColDefinitionDetails label="Status">
            <div className="flex justify-start items-center">
              <StatusDisk status={booking.status} />
              <h3 className="text-slate-900 font-normal pl-1">{bookingStatusNames[booking.status]}</h3>
            </div>
          </ColDefinitionDetails>
          <ColDefinitionDetails label="Car returned">{formatDate(booking.actualEnd)}</ColDefinitionDetails>
          <ColDefinitionDetails label="Start">{formatDate(booking.start)}</ColDefinitionDetails>
          <ColDefinitionDetails label="End">
            {isExtended ? (
              <div className="flex justify-start">
                <p className="line-through">{formatDate(booking.end)}</p>;
                <p className="px-1.5">{formatDate(booking.extension?.endTime)}</p>
              </div>
            ) : (
              formatDate(booking.end)
            )}
          </ColDefinitionDetails>
          <ColDefinitionDetails label="Requested">{formatDate(booking.requestedTime)}</ColDefinitionDetails>
          <ColDefinitionDetails label="Return time delta">{getReturnTimeDelta(booking)}</ColDefinitionDetails>
          <ColDefinitionDetails label="Order Reference">{booking.orderReference}</ColDefinitionDetails>
          <ColDefinitionDetails label="Booking ID">{booking.id}</ColDefinitionDetails>
          <ColDefinitionDetails label="Vehicle">{booking.vehicle.vin}</ColDefinitionDetails>
          <ColDefinitionDetails label="Vehicle License Plate">{booking.vehicle.regNr}</ColDefinitionDetails>
        </dl>
      </DetailsSection>
      <DetailsSection>
        <DetailsSectionHeading>Pick up & Drop off</DetailsSectionHeading>
        <DetailsMap
          coords={bookingCoordinates}
          parkingInfo={booking.location.parkingInfo}
          locationData={getBookingLocation(booking)}
        />
      </DetailsSection>
      <DetailsSection>
        <div className="flex justify-between items-center">
          <DetailsSectionHeading>Order details</DetailsSectionHeading>
          <Button onClick={handleOpenRedirectToStripeDialog} variant="white" size="small" extraClasses="mb-7">
            <Icon name="open_in_new" size="small" padding={0} />
            <span className="pl-2">Open payment in Stripe</span>
          </Button>
        </div>
        <div className="border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <RowDefinitionDetails label="Base">
              <PriceAndCurrency value={booking.cost.base} currency={booking.cost.currency} />
            </RowDefinitionDetails>
            <RowDefinitionDetails label="Fuel">
              <PriceAndCurrency value={booking.cost.fuel} currency={booking.cost.currency} />
            </RowDefinitionDetails>
            <RowDefinitionDetails label="Service">
              <PriceAndCurrency value={booking.cost.service} currency={booking.cost.currency} />
            </RowDefinitionDetails>
            <RowDefinitionDetails label="Total">
              <PriceAndCurrency value={booking.cost.total} currency={booking.cost.currency} />
            </RowDefinitionDetails>
          </dl>
        </div>
      </DetailsSection>
      <ColDefinitionDetails label="Fuel cost per liter">
        <PriceAndCurrency value={booking.cost.fuelPerLiter} currency={booking.cost.currency} />
      </ColDefinitionDetails>
      <div className="border rounded-md flex justify-start items-center mt-5">
        <Icon extraClasses="p-3" variant="gray" shape="circle" name="help" size="small" />
        <ul className="text-gray-500 list-disc ml-2 p-3 font-normal text-sm">
          <li>Prices are not final</li>
          <li>Reservation amount not included</li>
        </ul>
      </div>
      <DetailsSection>
        <DetailsSectionHeading>Availability details</DetailsSectionHeading>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <ColDefinitionDetails label="Cost per hour">
            <PriceAndCurrency value={booking.vehicle.price.hourly} currency={booking.cost.currency} />
          </ColDefinitionDetails>
          <ColDefinitionDetails label="Cost per day">
            <PriceAndCurrency value={booking.vehicle.price.daily} currency={booking.cost.currency} />
          </ColDefinitionDetails>
        </dl>
      </DetailsSection>
      <Dialog
        icon="date_range"
        isOpen={isExtendDialogOpen}
        onCancel={handleCloseExtendDialog}
        confirmText="Extend booking"
        cancelText="Cancel"
        onConfirm={() => handleExtendBooking()}
        title="Extend booking">
        <div>
          <p className="mt-5 font-medium">Select new end time and date</p>
          <div className="relative rounded-md shadow-sm mt-1">
            <input
              className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-lg"
              min={getMininumExtendedDate()}
              type="datetime-local"
              onChange={handleExtensionUpdate}
              value={extendedDate}
            />
          </div>
          {errorMessage && <p className="mt-2 text-sm text-danger-600">{errorMessage}</p>}
        </div>
      </Dialog>
      <Dialog
        icon="info"
        isOpen={isRedirectToStripeDialogOpen}
        title="Redirecting to stripe"
        onCancel={handleCloseRedirectToStripeDialog}
        onConfirm={handleRedirectToStripe}
        onClose={handleCloseRedirectToStripeDialog}
        cancelText="Cancel"
        confirmText="Proceed">
        We will open a new tab taking you to the Stripe dashboard. In order to see the payment, you first need access to
        Stripe.
      </Dialog>
    </div>
  );
}

export default Overview;
