import { serviceConfig } from '../../config';
import { axiosInstance } from '../../services/shared';
import { AxiosResponse } from 'axios';
import { ColabRecords } from './types';

async function getColabs() {
  try {
    const response: AxiosResponse<ColabRecords> = await axiosInstance.get(
      `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.colab}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching colab records:', error);
    throw error;
  }
}

export { getColabs };
