import { NotFound404 } from 'lynkco-up-core';

export default function accessDeniedPage() {
  return (
    <div>
      <NotFound404
        errorType=" "
        errorTitle="Access Denied"
        errorBody="Sorry, you do not have access to this tool."></NotFound404>
    </div>
  );
}
