import { Helmet } from 'react-helmet';
import { PageHeading } from '../../components';
import ColabsTable from './ColabsTable';

function Colab() {
  return (
    <div>
      <div className="z-10 bookings-table">
        <Helmet>
          <title>Co:Lab Data</title>
        </Helmet>
        <PageHeading title="Co:Lab Data" description="Co:lab data showed here"></PageHeading>
      </div>

      <div>
        <ColabsTable />
      </div>
    </div>
  );
}

export default Colab;
