import { Button, Icon, Table, Tbody, Td, Th, Thead, Tr } from 'lynkco-up-core';
import { useEffect, useState } from 'react';
import { LoadingMask } from '../../components';
import { formatDate } from '../../parts/shared/utils';
import { ColabRecords, GetColab } from './types';
import { getColabs } from './getColab';

function ColabsTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ColabRecords>();

  const fillerArray = new Array(5).fill(0).map(() => Math.random());

  async function fetchData() {
    try {
      const colabs = await getColabs();
      setData(colabs);
    } catch (error) {
      console.error('Error occurred while fetching colab records: ', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleOpenLogDetails(entity: GetColab) {
    console.log(entity);
  }

  return (
    <div className="z-10 availabilities-table mb-72">
      <div className="overflow-x-scroll">
        <Table extraClasses="min-w-max">
          <Thead extraClasses="!bg-neutral-50">
            {isLoading ? (
              <Tr>
                <Th>
                  <div className="pl-6 py-1.5">
                    <LoadingMask />
                  </div>
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
              </Tr>
            ) : (
              <Tr>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start ml-6">Date</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">Translated Feedback</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex items-center justify-start ml-6">
                    Car Model
                  </span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">App Coming From</span>
                </Th>
                <Th>
                  <span></span>
                </Th>
              </Tr>
            )}
          </Thead>
          <Tbody>
            {isLoading ? (
              fillerArray.map(_ => (
                <Tr key={_}>
                  <Td>
                    <div className="pl-5 py-3">
                      <LoadingMask />
                    </div>
                  </Td>
                  <Td>
                    <LoadingMask />
                  </Td>
                  <Td>
                    <LoadingMask />
                  </Td>
                  <Td>
                    <LoadingMask />
                  </Td>
                  <Td cellWidth={100} align="right">
                    <LoadingMask />
                  </Td>
                </Tr>
              ))
            ) : data ? (
              data?.entities.map((entity, index) => (
                <Tr key={index}>
                  <Td>
                    <span className="font-medium pl-6 text-black flex justify-start">
                      {' '}
                      {formatDate(entity.timestamp)}
                    </span>
                  </Td>
                  <Td>
                    <div className="px-6 py-2 text-sm text-neutral-800">{entity.translated}</div>
                  </Td>
                  <Td>
                    <div className="px-6 py-2 text-sm text-neutral-800">{entity.vehicleModel}</div>
                  </Td>
                  <Td>
                    <div className="px-6 py-2 text-sm text-neutral-800">{entity.fromAppName}</div>
                  </Td>
                  <Td cellWidth={100} align="right">
                    <div className="flex items-center justify-end pr-2">
                      <Button
                        onClick={() => handleOpenLogDetails(entity)}
                        variant="transparent"
                        size="small"
                        extraClasses="mr-2">
                        <Icon name="list_alt" size="small" variant="gray" />
                      </Button>
                    </div>
                  </Td>
                </Tr>
              ))
            ) : (
              <p>Loading Colab records</p>
            )}
          </Tbody>
        </Table>
      </div>
      {/* <SlideOver isOpen={detailsIsOpen} onCancel={() => setDetailsIsOpen(false)}></SlideOver>     */}
    </div>
  );
}

export default ColabsTable;
